<!--
   /**
      * nanny.vue
      * @module {src/modules/public}
      * @desc 前端我们的项目
      * @author shiqiu
      * @date 2021-01-21
      */
-->
<template>
  <div>
  <el-row :gutter="10" style="margin-bottom: 20px">
    <el-col :xs="24">
      <p class="big-title">保姆服务</p>
    </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6"
                      style="cursor: pointer;margin-bottom: 10px"
                      v-for="(item,index) in list" :key="index"
                      @click.native="goToNews(item.service_code)">
                <el-card shadow="hover" style="border: 1px solid #dedede">
                  <p style="text-align: center"><img :src="item.img" style="width: 240px; height: 240px; border-radius: 10px"></p>
                  <p class="title">{{item.title}}</p>
                  <p class="amount">{{item.amount}}</p>
                  <p class="orgName">发布者：{{item.org_name}}</p>
                </el-card>
              </el-col>
<!--              <el-col :span="24" style="margin-bottom: 10px">-->
<!--                <div class="moreBg">-->
<!--                  <p class="more" @click="toSale">更多</p>-->
<!--                </div>-->
<!--              </el-col>-->
            </el-row>
  </div>
</template>

<script>
import {searchNanny} from '@/api/service/service'
export default {
  name: "Login",
  components:{},
  data() {
    return {
      list:[
      ],
    }
  },
  methods: {
    initData() {
      this.getData()
    },
    async getData() {
      const data = {skip: 0, limit: 10}
      let res = await searchNanny(data)
      if (res.status) {
        this.list = res.data
      } else {
        this.$message({showClose: true, message: res.msg, type: 'error'})
      }
    },
    goToNews (service_code) {
      this.$router.push(
        {
          path: '/serviceDetails',
          query: {
            service_code
          }
        }
      )
    },
    // toSale () {
    //   this.$router.push(
    //     {
    //       path: '/saleList',
    //     }
    //   )
    // },
  },
  created() {
    this.initData()
  }
}
</script>


<style lang="scss" scoped>
.big-title{
  font-size: 18px;
  text-align: left;
  float: left;
  margin-bottom: 20px;
  color: #f90;
  line-height: 40px;
  border-bottom: 2px solid #f90;
}
.title{
  font-size: 18px;
  text-align: left;
  padding-top: 10px;
  color: #666;
}
.orgName{
  font-size: 14px;
  text-align: right;
  color: #999;
}
.amount{
  font-size: 28px;
  color: #f90;
  text-align: left;
  padding-bottom: 10px;
}
</style>
