import Vue from "vue";
//用来展示数据列表页面
/**
 * 新闻列表
 *
 * @date     2021-01-04
 * @author   shiqiu
 */

export function searchNanny(data) {
  return new Promise((resolve, reject) => {
    Vue.axios({
      method: "post",
      url: "/service/search_nanny",
      data
    })
      .then(res => {
        // console.log(res.data)
        // 请求成功
        resolve(res);
      })
      .catch(error => {
        // 请求失败
        // console.log(error)
        reject(error);
      });
  });
}

export function searchCleanKeeping(data) {
  return new Promise((resolve, reject) => {
    Vue.axios({
      method: "post",
      url: "/service/search_clean_keeping",
      data
    })
      .then(res => {
        // console.log(res.data)
        // 请求成功
        resolve(res);
      })
      .catch(error => {
        // 请求失败
        // console.log(error)
        reject(error);
      });
  });
}

export function searchHousing(data) {
  return new Promise((resolve, reject) => {
    Vue.axios({
      method: "post",
      url: "/service/search_housing",
      data
    })
      .then(res => {
        // console.log(res.data)
        // 请求成功
        resolve(res);
      })
      .catch(error => {
        // 请求失败
        // console.log(error)
        reject(error);
      });
  });
}

export function searchPlumbing(data) {
  return new Promise((resolve, reject) => {
    Vue.axios({
      method: "post",
      url: "/service/search_plumbing",
      data
    })
      .then(res => {
        // console.log(res.data)
        // 请求成功
        resolve(res);
      })
      .catch(error => {
        // 请求失败
        // console.log(error)
        reject(error);
      });
  });
}

export function searchHot(data) {
  return new Promise((resolve, reject) => {
    Vue.axios({
      method: "post",
      url: "/service/search_hot",
      data
    })
      .then(res => {
        // console.log(res.data)
        // 请求成功
        resolve(res);
      })
      .catch(error => {
        // 请求失败
        // console.log(error)
        reject(error);
      });
  });
}

export function searchLatest(data) {
  return new Promise((resolve, reject) => {
    Vue.axios({
      method: "post",
      url: "/service/search_latest",
      data
    })
      .then(res => {
        // console.log(res.data)
        // 请求成功
        resolve(res);
      })
      .catch(error => {
        // 请求失败
        // console.log(error)
        reject(error);
      });
  });
}

export function searchBestSelling(data) {
  return new Promise((resolve, reject) => {
    Vue.axios({
      method: "post",
      url: "/service/search_best_selling",
      data
    })
      .then(res => {
        // console.log(res.data)
        // 请求成功
        resolve(res);
      })
      .catch(error => {
        // 请求失败
        // console.log(error)
        reject(error);
      });
  });
}

export function searchNannyInfo(service_code) {
  return new Promise((resolve, reject) => {
    Vue.axios({
      method: "post",
      url: "/service/search_info",
      data: {
        service_code
      }
    })
      .then(res => {
        // console.log(res.data)
        // 请求成功
        resolve(res);
      })
      .catch(error => {
        // 请求失败
        // console.log(error)
        reject(error);
      });
  });
}
